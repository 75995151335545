// Import public packages
import React, { useEffect, useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { AutoAwesomeMotionRounded, AutoAwesomeRounded } from '@mui/icons-material';
import { Button, TextField, Typography } from '@mui/material';
import { faArrowLeft, faArrowRight, faBars, faDownload, faFileCircleMinus, faFileCirclePlus, faGears, faSave } from '@fortawesome/free-solid-svg-icons';

// Import local helper functions
import { accentColor, getTheme } from '../../utils/projectColors';
import { generateFlashcardSet } from '../../scripts/geminiRequestManager.js';
import { route } from '../../utils/router.js';
import { getCurrentUser } from '../../scripts/accountManager';
import { formatDate } from '../../utils/algorithms';
import { isMobileDevice } from '../../utils/detectDevice.js';
import { saveFlashcardSet } from '../../scripts/databaseManager.js';
import { generateFlashFile, parseFLSHintoObj, parseIntoFLSH } from '../../scripts/flashcardEncoder.js';

// Import local components
import Loader from '../../components/Loader/Loader';
import BackButton from '../../components/BackButton/BackButton.jsx';
import NiceButton from '../../components/NiceButton/_layout.jsx';
import HomePage from '../Home-page/homePage.jsx';
import { FlashCard } from '../Flashcard-view/flashCardView.jsx';

// Import local styles
import './_styles-simple.scss';
import 'react-toastify/dist/ReactToastify.css';
import geminiText from '../../assets/images/Google Gemini logo.png';
import AreYouSurePopup from '../../components/popups/Alert.jsx';



function SimpleEditor({ newSet=false, className, recursion=false, editMode=false, flashcardData=null, useBeta=false, previousComponent=<HomePage />, cloudUpdate=false }) {
  const [initialLoad, setInitialLoad] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [flashcardSet, setFlashcardSet] = useState(flashcardData);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [focusFront, setFocusFront] = useState(false);
  const [focusBack, setFocusBack] = useState(false);
  const [shouldAutoFocus, setShouldAutoFocus] = useState(false);
  const [saveAlertOpen, setSaveAlertOpen] = useState(false);

  const [flashcardSlide, setFlashcardSlide] = useState(0);
  const [flashcardFlipped, setFlashcardFlipped] = useState(false);
  const [relFlashcardData, setRelFlashcardData] = useState(flashcardData !== null ? flashcardData.cards[0] : null);
  const [totalCardsLength, setTotalCardsLength] = useState(flashcardData !== null ? flashcardData.cards.length : 0);
  const [titleField, setTitleField] = useState(flashcardData !== null ? flashcardData.title : '');

  // states for AI generating flashcard set
  const [AITopic, setAITopic] = useState('');
  const [AIloading, setAIloading] = useState(false);
  const [AILength, setAILength] = useState(0);
  const [AILanguage, setAILanguage] = useState('english');

  const AILock = false;

  useEffect(() => {
    console.log('Flashcard set: ', flashcardSet);
    console.log('Auth: ', getCurrentUser().user);
  }, [flashcardSet]);

  // First Load
  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
      setLoaded(true);
    }
  }, [initialLoad]);

  // handle the change for the flashcard slide
  useEffect(() => {
    if (flashcardSet !== null && shouldAutoFocus) {
      setRelFlashcardData(flashcardSet.cards[flashcardSlide]);
      setFocusFront(true);
      setFocusBack(false);
      setShouldAutoFocus(false);  // Reset after focusing
    }
  }, [flashcardSlide, shouldAutoFocus]);
  
  useEffect(() => {
    if (shouldAutoFocus) {
      if (flashcardFlipped) {
        setFocusBack(true);
        setFocusFront(false);
      } else {
        setFocusFront(true);
        setFocusBack(false);
      }
      setShouldAutoFocus(false);  // Reset after focusing
    }
  }, [flashcardFlipped, shouldAutoFocus]);

  // Handle the hotkeys for the flashcard editor
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Only process hotkeys if no text input is active
      // if (!isTextInputActive()) {

      // console.log('Key: ', e.key);

      if (e.ctrlKey) {
        if (e.key === 'a') {
          e.preventDefault(); 
          if (flashcardSlide > 0) {
            setFlashcardSlide((prev) => prev - 1);
            setShouldAutoFocus(true);
            setFlashcardFlipped(false);
          }
        } else if (e.key === 'd') {
          e.preventDefault(); 
          if (flashcardSlide < flashcardSet.cards.length - 1) {
            setFlashcardSlide((prev) => prev + 1);
            setShouldAutoFocus(true);
            setFlashcardFlipped(false);
          } else {
            handleAddFlashcard(e);
            setShouldAutoFocus(true);
          }
        } else if (e.key === 'f') {
          e.preventDefault(); 
          setFlashcardFlipped((prev) => !prev);
          if (flashcardFlipped) {
            setTimeout(() => {
              setShouldAutoFocus(true);
            }, 500);
          }
        } else if (e.key === 'e' || e.key === 'ArrowUp') {
          e.preventDefault();
          handleAddFlashcard(e);
          setShouldAutoFocus(true);
        } else if (e.key === 'q' || e.key === 'ArrowDown') {
          e.preventDefault();
          handleRemoveFlashcard(e);
          setShouldAutoFocus(true);
        } else if (e.key === 's') {
          e.preventDefault();
          handleSaveFlashcardSet(e);
        }
      } else if (e.key === 'Escape') {
        e.preventDefault();

        let element = document.querySelector('.save-alert');
        let bgBlur = document.querySelector(`.bg-blur-save-alert`);
        console.log('State', element.classList.contains('popup-move-in-mn'));

        if (element.classList.contains('popup-move-in-mn')) {
          element.classList.remove('popup-move-in-mn');
          element.classList.add('popup-move-out-mn');

          bgBlur.classList.remove('blur-active');

          setTimeout(() => {
            setSaveAlertOpen(false);
          }, 300);
        } else {
          element.classList.remove('popup-move-out-mn');
          element.classList.add('popup-move-in-mn');

          bgBlur.classList.add('blur-active');
        }
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [flashcardSlide, flashcardSet]);


  // handle the click for AI generating Flashcard set
  const handleAIGenerate = (event) => {
    setInitialLoad(null);
  }

  // TODO: Make it responsive

  // handle the opening and closing of the mobile bar
  const handleOpenMobilBar = (event) => {
    const mobileBar = document.querySelector('.mobile-bar');
    const bgBlur = document.querySelector('.bg-blur');

    if (mobileBar.classList.contains('menu-bar-out')) {
      mobileBar.classList.remove('menu-bar-out');
      mobileBar.classList.add('menu-bar-in');
      
      bgBlur.classList.remove('blur-out');
      bgBlur.classList.add('blur-in');
    } else {
      mobileBar.classList.remove('menu-bar-in');
      mobileBar.classList.add('menu-bar-out');

      bgBlur.classList.remove('blur-in');
      bgBlur.classList.add('blur-out');
    }
  }

  // handle the creation of a new flashcard set 
  const handleCreateFlashcardSet = (event) => {
    event.preventDefault();

    route(
      <SimpleEditor
        recursion
        newSet
        flashcardData={{
          title: 'New Flashcard Set',
          author: getCurrentUser().user.displayName,
          description: 'A new flashcard set created using the simple flashcard editor',
          authorUID: getCurrentUser().user.uid,
          cardSorting: false,
          date: formatDate(new Date(), false),
          id: "",
          path: "/",
          lastOpened: formatDate(new Date(), true),
          shuffleCards: false,
          progress: 0,
          timesViewed: 0,
          cards: [
            {
              front: '',
              back: ''
            },
            {
              front: '',
              back: ''
            },
            {
              front: '',
              back: ''
            },
            {
              front: '',
              back: ''
            },
            {
              front: '',
              back: ''
            }
          ],
        }}
      />
    )
  }

  // generate the flashcard set
  const handleGenerateFlashcardSet = (event) => {
    event.preventDefault();

    if (AITopic === '') {
      toast.warn('Please enter a topic');
      return;
    } else if (AILength === '') {
      toast.warn('Please enter the number of flashcards');
    } else if (AILength < 5) {
      toast.warn('Flashcard set must have at least 5 flashcards');
    } else {
      if (!AILock) {
        generateFlashcardSet(
          AITopic, 
          AILength, 
          getCurrentUser().user.displayName, 
          AILanguage,
          (response) => {
            if (response.status === 'generating') {
              setAIloading(true);
              toast.info('Generating flashcard set...');
            } else if (response.status === 'success') {
              setAIloading(false);
              toast.success('Flashcard set generated successfully');

              setAITopic(''); 
              setAILength('');
              setAILanguage('english');

              setTimeout(() => {
                route(<SimpleEditor 
                  editMode
                  newSet
                  flashcardData={response.response} 
                />)
              }, 1000);
            } else if (response.status === 'error') {
              setAIloading(false);

              console.error('Error generating flashcard set', response.error);

              if (response.error.status === 400) {
                toast.error('This feature is currently only available outside of the EU');
              } else {
                toast.error('An error occurred while generating the flashcard set');
              }
            }
          }
        )
      }
    }
  }

  // handle the addition of a new flashcard
  const handleAddFlashcard = (event) => {
    let newFlashcardSet = flashcardSet;
    newFlashcardSet.cards = [
      ...newFlashcardSet.cards.slice(0, flashcardSlide + 1),
      {
        front: '',
        back: ''
      },
      ...newFlashcardSet.cards.slice(flashcardSlide + 1)
    ]

    setFlashcardSet(newFlashcardSet);
    setTotalCardsLength((prev) => prev + 1)
    setFlashcardSlide((prev) => prev + 1);
    setFlashcardFlipped(false);
    setShouldAutoFocus(true);
    toast.success('Flashcard added successfully');
  }

  // Handles the removal of a flashcard
  const handleRemoveFlashcard = (event) => {
    if (flashcardSet.cards.length >= 5) {
      let newFlashcardSet = flashcardSet;
      newFlashcardSet.cards.splice(flashcardSlide, 1);
      setFlashcardSlide((prev) => prev - 1);
      setFlashcardSet(newFlashcardSet);
      setTotalCardsLength((prev) => prev - 1);
      setShouldAutoFocus(true);
      toast.success('Flashcard removed successfully');
    } else {
      toast.warn('Flashcard set must have at least 5 flashcards');
    }
  }

  // handle the saving of the flashcard set
  const handleSaveFlashcardSet = async (event) => {
    let newFlashcardSet = flashcardSet;

    toast.info('Saving flashcard set...');

    newFlashcardSet.lastOpened = formatDate(new Date(), true);
    newFlashcardSet.progress = 0;
    newFlashcardSet.timesViewed = 0;
    newFlashcardSet.title = titleField;

    if (newSet) {
      // Save the flashcard set to the database
      await saveFlashcardSet(newFlashcardSet);
    } else { 
      // Update the flashcard set in the database
      await saveFlashcardSet(newFlashcardSet, newFlashcardSet.id);
    }

    toast.success('Flashcard set saved successfully');
    setTimeout(() => {
      route(<HomePage />);
    }, 1000);
  }

  // handle the downloading of the flashcard set
  const handleDownloadFlashcardSet = async (event) => {
    let newFlashcardSet = flashcardSet;

    toast.info('Generating downloadable flashcard set...');

    newFlashcardSet.lastOpened = formatDate(new Date(), true);
    newFlashcardSet.progress = 0;
    newFlashcardSet.timesViewed = 0;
    newFlashcardSet.title = titleField;

    // Download the .flsh file to the user's device
    const flashcardData = await parseIntoFLSH(newFlashcardSet);

    if (!flashcardData.success) {
      toast.error('An error occurred while generating the file');
      console.error('Error generating flashcard file', flashcardData.error);
    }

    const result = generateFlashFile(flashcardData.data.fileParams.fileName, flashcardData.data.encryptedData);

    if (result.success) {
      toast.success('Flashcard set downloaded successfully');
    } else {
      toast.error('An error occurred while downloading the file');
      console.error('Error downloading flashcard file', result.error);
    }
  }

  function MuiFlashInput(
    { className, initialText, textLabel, onEnter=()=>{}, inputSX={}, sx={}, onChange=()=>{}, focus=false }
  ) {
    const [inputText, setInputText] = useState(initialText);
    const inputRef = useRef(null);

    useEffect(() => {
      if (focus && inputRef.current) {
        inputRef.current.focus();
        
        // Set cursor to the end of the text
        const length = inputRef.current.value.length;
        inputRef.current.setSelectionRange(length, length);
      }
    }, [focus]);

    // TODO: Add a max character limit for the text input
    // TODO: Add generative AI in separate parts of flashcards using double slash //generate a summary of javascript in 5 words// and other variables like {{title}} and {{author}}
    // TODO: Add elements to flashcard editor (images, gifs, text area (auto detects links and makes them clickable), code blocks, etc.)

    const handleInputChange = (e) => {
      const value = e.target.value;
      const lineCount = (value.match(/\n/g) || []).length + 1;
  
      if (lineCount <= 8) {
        setInputText(value);
        onChange(e);
      } else {
        // Prevent adding more text if it exceeds the max rows
        const lines = value.split('\n').slice(0, 8).join('\n');
        setInputText(lines);
      }
    };
    
    return (
      <div style={{ 
        width: '100%', 
        height: '100%', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center' 
      }}>
        <TextField
          className={className}
          id="outlined-basic"
          label={textLabel}
          inputRef={inputRef}
          InputProps={{
            sx : {
              fontSize: '2.5rem',
              padding: '16.5px 14px',
            }
          }}
          InputLabelProps={{
            sx: {
              color: 'rgba(255, 255, 255, .4)',
              ...inputSX
            }
          }}
          variant="outlined"
          fullWidth
          multiline
          minRows={1}
          maxRows={8}
          sx={{
            margin: '20px',
            marginInline: '40px',
            width: 'stretch',
            '& label.Mui-focused': {
              color: 'rgba(255, 255, 255, 1)',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(255, 255, 255, .6)',
              },
              '&:hover fieldset': {
                borderColor: 'rgba(255, 255, 255, .6)',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'rgba(255, 255, 255, .6)',
              },
            },
            '& .MuiInputBase-input': {
              color: 'rgba(255, 255, 255, 1)',
              fontSize: isMobileDevice()?'20px' :'2.5rem'
            },
            ...sx
          }}
          value={inputText}
          onChange={(e) => handleInputChange(e)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onEnter(e);
            }
          }}
        />
      </div>
    )
  }

  const handleSettingsOpen = (event) => {
    const settingsElement = document.querySelector('.settings-panel');
    const bgBlur = document.querySelector('.bg-blur');
    const production = true;

    if (!production) {
      if (settingsOpen) {
        if (settingsElement.classList.contains('hidden')) {
          settingsElement.classList.remove('hidden');
        }

        bgBlur.classList.remove('blur-in');
        bgBlur.classList.add('blur-out');

        settingsElement.classList.remove('animate-in');
        settingsElement.classList.add('animate-out');
        setSettingsOpen(false);

      } else {
        bgBlur.classList.remove('blur-out');
        bgBlur.classList.add('blur-in');

        settingsElement.classList.remove('animate-out');
        settingsElement.classList.add('animate-in');
        setSettingsOpen(true);
      }
    } else {
      toast.warn('This feature is not yet available');
    }
  }

  if (!loaded) {
    return <Loader primaryColor={accentColor} scale={2} />;
  }

  // If the page is not in edit mode, show the initial prompt
  if (initialLoad === false && !recursion && !editMode) {
    return (
      <div className='alert-main'>
        <div className='inner'> 
          <h1>
            Make a new Flashcard Set using simple flashcard editor
          </h1>
          <div className='selection'>
            <div className='left container' onClick={(e) => handleCreateFlashcardSet(e)}>
              <AutoAwesomeMotionRounded className='icon'/>
              <h1>
                Generate a flashcard set manually using built-in editor
              </h1>
            </div>

            <div className='right container' onClick={(e) => handleAIGenerate(e)}>
              <AutoAwesomeRounded className='icon'/>
              <h1>
                Generate a flashcard set using AI
              </h1>
              <span>
                <img src={geminiText} alt='Gemini Logo' />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`main-container ${className}`}>
      {initialLoad === null?
        <div className='AI-generate-prompt'>
          {AIloading ?
            <Loader primaryColor={"#ffffff"} scale={2} type="square-of-dots" boxed sx={{
              maxHeight: '100px',
              maxWidth: '100px',
              backgroundColor: 'transparent'
            }}/>
            :
            <>
              <AutoAwesomeRounded className='icon'/>
              <h1>
                What kind of flashcard set would you like to generate?
              </h1>
              <TextField
                id="outlined-basic"
                label="Enter a topic"
                InputLabelProps={{
                  sx: {
                    color: 'rgba(255, 255, 255, .4)',
                  }
                }}
                variant="outlined"
                fullWidth
                multiline
                maxRows={3}
                sx={{
                  marginTop: '20px',
                  '& label.Mui-focused': {
                    color: 'rgba(255, 255, 255, .6)',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'rgba(255, 255, 255, .6)',
                    },
                    '&:hover fieldset': {
                      borderColor: 'rgba(255, 255, 255, .6)',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'rgba(255, 255, 255, .6)',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: 'rgba(255, 255, 255, .6)',
                  },
                }}
                value={AITopic}
                onChange={(e) => setAITopic(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    // handleGenerateFlashcardSet(e);
                  }
                }}
              />

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography
                  variant='body1'
                  sx={{
                    color: 'rgba(255, 255, 255, .6)',
                    marginTop: '20px'
                  }}
                >
                  Number of flashcards: 
                </Typography>

                <TextField
                  type="outlined-basic"
                  inputProps={{
                    min: 0,
                    max: 300,
                    sx: {
                      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                        backgroundColor: '#003687', // Change this to your desired color
                      },
                      '&::-webkit-outer-spin-button:hover, &::-webkit-inner-spin-button:hover': {
                        backgroundColor: '#005592', // Change this to your desired hover color
                      },
                    }
                  }}
                  variant="outlined"
                  value={AILength}
                  onChange={(e) => {
                    if (!isNaN(parseInt(e.target.value))) {
                      if (parseInt(e.target.value) >= 1 && parseInt(e.target.value) <= 300) {
                        setAILength(parseInt(e.target.value));
                      } else {
                        if (parseInt(e.target.value) <= 0) {
                          setAILength('');
                          toast.warn('Flashcard set must have at least 5 flashcards');
                        } else {
                          setAILength(300);
                          toast.warn('Flashcard set has a maximum of 300 flashcards');
                        }
                      }
                    } else if (e.target.value === '') {
                      setAILength('');
                    } else {
                      setAILength('');
                      toast.warn('Please enter a valid number');
                    }
                  }}
                  sx={{
                    marginLeft: '20px',
                    marginTop: '20px',
                    width: '110px',
                    '& label.Mui-focused': {
                      color: 'rgba(255, 255, 255, .6)',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgba(255, 255, 255, .6)',
                      },
                      '&:hover fieldset': {
                        borderColor: 'rgba(255, 255, 255, .6)',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'rgba(255, 255, 255, .6)',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: 'rgba(255, 255, 255, .6)',
                    },
                  }}
                />
              </div>

              <Typography
                variant='body1'
                sx={{
                  color: 'rgba(255, 255, 255, .6)',
                  marginTop: '20px'
                }}
              >
                Language for flashcard set: 
              </Typography>

              <TextField
                type="outlined-basic"
                inputProps={{
                  min: 0,
                  max: 300,
                  sx: {
                    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                      backgroundColor: '#003687', // Change this to your desired color
                    },
                    '&::-webkit-outer-spin-button:hover, &::-webkit-inner-spin-button:hover': {
                      backgroundColor: '#005592', // Change this to your desired hover color
                    },
                  }
                }}
                variant="outlined"
                value={AILanguage}
                fullWidth
                onChange={(e) => setAILanguage(e.target.value)}
                sx={{
                  marginTop: '20px',
                  '& label.Mui-focused': {
                    color: 'rgba(255, 255, 255, .6)',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'rgba(255, 255, 255, .6)',
                    },
                    '&:hover fieldset': {
                      borderColor: 'rgba(255, 255, 255, .6)',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'rgba(255, 255, 255, .6)',
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: 'rgba(255, 255, 255, .6)',
                  },
                }}
              />

              <Button
                variant='contained'
                sx={{
                  backgroundColor: '#003687',
                  color: 'white',
                  marginTop: '20px',
                  width: '60%',
                  '&:hover': {
                    backgroundColor: '#005592',
                  }
                }}
                onClick={(e) => handleGenerateFlashcardSet(e)}
              >
                Generate
              </Button>
            </>
          }
        </div>
        :
        <div className='main-edit-cont'>
          {isMobileDevice() ?
            <div className='mobile-bar menu-bar-out' style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexDirection: 'column',
              position: 'absolute',
              top: '20px',
              right: '0',
              zIndex: '100',
              height: 'auto',
              marginRight: '20px',
              paddingRight: '5px',
              paddingTop: '5px',
              borderRadius: '20px', 
              backgroundColor: '#25196D',
              boxSizing: 'border-box',
            }}>
              <NiceButton
                icon={faBars}
                hasIcon
                sx={{
                  margin: '10px',
                  zIndex: '101 !important'
                }}
                onClick={(e) => handleOpenMobilBar(e)}
              />

              <BackButton
                sx={{
                  margin: '10px',
                  position: 'unset'
                }}
              />

              <NiceButton
                icon={faFileCirclePlus}
                hasIcon
                sx={{
                  margin: '10px'
                }}
                onClick={(e) => handleAddFlashcard(e)}
              />
              <NiceButton
                icon={faFileCircleMinus}
                hasIcon
                sx={{
                  margin: '10px'
                }}
                onClick={(e) => handleRemoveFlashcard(e)}
              />
              <NiceButton
                icon={faGears}
                hasIcon
                sx={{
                  margin: '10px'
                }}
                onClick={(e) => handleSettingsOpen(e)}
              />
              <NiceButton
                icon={faSave}
                hasIcon
                sx={{
                  margin: '10px'
                }}
                onClick={(e) => handleSaveFlashcardSet(e)}
              />
              <NiceButton
                icon={faDownload}
                hasIcon
                sx={{
                  margin: '10px'
                }}
                fontSize='25px'
                onClick={(e) => {
                  handleDownloadFlashcardSet(e);
                }}
              />
            </div>
            :
            null
          }
          <div className='menu-bar'>
            {isMobileDevice() ?
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                width: '95%'
              }}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '80%'
                }}>
                  <TextField 
                    id="outlined-basic" 
                    label="title" 
                    variant="outlined" 
                    fullWidth
                    InputLabelProps={{
                      sx: {
                        color: 'rgba(255, 255, 255, 1)',
                      }
                    }}
                    sx={{
                      margin: '10px',
                      '& label.Mui-focused': {
                        color: 'rgba(255, 255, 255, 1)',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'rgba(255, 255, 255, .6)',
                        },
                        '&:hover fieldset': {
                          borderColor: 'rgba(255, 255, 255, .6)',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'rgba(255, 255, 255, .6)',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color: 'rgba(255, 255, 255, 1)',
                      },
                    }}
                    value={titleField}
                    onChange={(e) => e.target.value.length <= 30? setTitleField(e.target.value) : null}
                  />
                </div>
              </div>
              :
              <>
                <div>
                  <NiceButton sx={{ position: 'unset', margin: '5px' }} onClick={(e) => setSaveAlertOpen(true)} hasIcon icon={faArrowLeft}/>
                  <h2>
                    {flashcardSet.author} - {flashcardSet.date}
                  </h2>
                </div>

                <div>
                  <TextField 
                    id="outlined-basic" 
                    label="title" 
                    variant="outlined" 
                    InputLabelProps={{
                      sx: {
                        color: 'rgba(255, 255, 255, 1)',
                      }
                    }}
                    sx={{
                      margin: '10px',
                      width: '300px',
                      '& label.Mui-focused': {
                        color: 'rgba(255, 255, 255, 1)',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'rgba(255, 255, 255, .6)',
                        },
                        '&:hover fieldset': {
                          borderColor: 'rgba(255, 255, 255, .6)',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'rgba(255, 255, 255, .6)',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color: 'rgba(255, 255, 255, 1)',
                      },
                    }}
                    value={titleField}
                    onChange={(e) => e.target.value.length <= 30? setTitleField(e.target.value) : null}
                  />
                </div>

                <div>
                  <h2>
                    {flashcardSlide + 1} / {totalCardsLength}
                  </h2>
                  <NiceButton 
                    icon={faFileCirclePlus}
                    hasIcon
                    sx={{
                      margin: '10px',
                      marginLeft: isMobileDevice() ? '10px' : '40px'
                    }}
                    fontSize='25px'
                    onClick={(e) => handleAddFlashcard(e)}
                  />

                  <NiceButton 
                    icon={faFileCircleMinus}
                    hasIcon
                    sx={{
                      margin: '10px',
                    }}
                    fontSize='25px'
                    onClick={(e) => handleRemoveFlashcard(e)}
                  />

                  {/* TODO: Implement flashcard settings panel */}
                  <NiceButton
                    icon={faGears}
                    hasIcon
                    sx={{
                      margin: '10px'
                    }}
                    fontSize='25px'
                    onClick={(e) => {
                      handleSettingsOpen(e);
                    }}
                  />

                  <NiceButton 
                    icon={faSave}
                    hasIcon
                    sx={{
                      margin: '10px'
                    }}
                    fontSize='25px'
                    onClick={(e) => handleSaveFlashcardSet(e)}
                  />

                  <NiceButton
                    icon={faDownload}
                    hasIcon
                    sx={{
                      margin: '10px'
                    }}
                    fontSize='25px'
                    onClick={(e) => {
                      handleDownloadFlashcardSet(e);
                    }}
                  />
                </div>
              </>
            }
          </div>
          <div className='main-edit'>
            <div className='left'>
              {useBeta ? 
                null
                :
                <h1>
                  Elements will be added soon!
                </h1>
              }
            </div>
            
            <div className='middle'>
              <div className='flash-cont'>
                {/* TODO: animate the flashcards */}
                <FlashCard
                  frontSide={
                    <MuiFlashInput 
                      initialText={relFlashcardData.front}
                      onChange={(e) => {
                        let newFlashcardSet = flashcardSet;
                        newFlashcardSet.cards[flashcardSlide].front = e.target.value;
                        setFlashcardSet(newFlashcardSet);
                      }}
                      focus={focusFront}
                    />
                  }
                  backSide={
                    <MuiFlashInput 
                      initialText={relFlashcardData.back} 
                      onChange={(e) => {
                        let newFlashcardSet = flashcardSet;
                        newFlashcardSet.cards[flashcardSlide].back = e.target.value;
                        setFlashcardSet(newFlashcardSet);
                      }}
                      focus={focusBack}     
                    />
                  }
                  flipped={flashcardFlipped}
                  flipDynamically
                  disableClick
                  showCardLabels
                  cardWidth='95%'
                  onFlip={(e) => setTimeout(() => setShouldAutoFocus(true), 701)}
                />
              </div>

              <div className='bottom-buttons'>
                <NiceButton
                  icon={faArrowLeft}
                  hasIcon 
                  sx={{
                    margin: '10px'
                  }}
                  onClick={() => {
                    if (flashcardSlide > 0) {
                      setFlashcardSlide((prev) => prev - 1);
                      setShouldAutoFocus(true);
                      setFlashcardFlipped(false);
                    }
                  }}
                />
                <NiceButton
                  text="Flip"
                  sx={{
                    margin: '10px',
                    width: '100px'
                  }}
                  onClick={() => {
                    setFlashcardFlipped((prev) => !prev);
                    console.log(flashcardFlipped);
                    setShouldAutoFocus(true);
                  }}
                />
                <NiceButton
                  icon={faArrowRight}
                  hasIcon 
                  sx={{
                    margin: '10px'
                  }}
                  onClick={() => {
                    if (flashcardSlide < flashcardSet.cards.length - 1) {
                      setFlashcardSlide((prev) => prev + 1);
                      setShouldAutoFocus(true);
                      setFlashcardFlipped(false);
                    }
                  }}
                />
              </div>
            </div>
            
            <div className='right'>
              {useBeta ? 
                null
                :
                <h1>
                  Elements will be added soon!
                </h1>
              }
            </div>
          </div>
        </div>
      }

      <div className='settings-panel hidden'>

      </div>
      <div className='bg-blur' />
      
      <AreYouSurePopup
        open={saveAlertOpen}
        alert
        hasCloseButton
        message='Do you want to save the changes before exiting?'
        confirmText='Save'
        cancelText='Exit'
        onConfirm={(e) => handleSaveFlashcardSet(e)}
        onCancel={(e) => route(previousComponent)}
        onClose={(e) => setSaveAlertOpen(false)}
        className="save-alert"
        sx={{
          color: 'white'
        }}
      />

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        pauseOnHover={false}
        closeOnClick
        rtl={false}
        theme={getTheme()}
      />
    </div>
  );
}

export default SimpleEditor;