import React, { useEffect, useState, useRef, useCallback } from "react";

import { SwiperSlide, Swiper } from "swiper/react";
import { EffectCards, Virtual } from "swiper/modules";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGears, faListCheck, faPencil, faRepeat, faTrash, faArrowLeft, faArrowRight, faBars, faShuffle, faFileCircleCheck, faInfo, faGlassCheers } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from 'react-toastify';

// Import useful components
import { formatDate, deepCopy, convertNewlinesToBr, formatText } from "../../utils/algorithms";
import { accentColor, getTheme, secondaryColor } from "../../utils/projectColors";
import { modifyFlashcardField, removeFlashcardSet, saveFlashcardSet } from "../../scripts/databaseManager";
import { route } from "../../utils/router";

// Import Styles
import "./flashCardView.scss";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'react-circular-progressbar/dist/styles.css';

// Import components from other pages
import Loader from "../../components/Loader/Loader";
import HomePage from "../Home-page/homePage";
import BackButton from "../../components/BackButton/BackButton";
import AreYouSurePopup from "../../components/popups/Alert";
import SimpleEditor from "../Flash-card-editor/flashCardEditorSimple";

function CircularProgress ({ percentage, textColor='#f88', trailColor='#d6d6d6', backgroundColor="#3e98c7", pathColor='#59E8B5' ,width='100px', height='100px'}) {
  return (
    <div style={{ width: width, height: height }}>
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        styles={buildStyles({
          // Customize the path color
          pathColor: pathColor,
          // Customize the text color
          textColor: textColor,
          // Customize the background color
          trailColor: trailColor,
          backgroundColor: backgroundColor,
        })}
      />
    </div>
  );
};

function FlashCard ({ 
  // Props
  frontSide="", backSide="", className, cardColor="#003687", textColor="#fff", cardWidth="100%", 
  cardHeight="100%", defaultSide="front", flipDuration=.7, fillScreen=false, sx={}, onFlip=()=>{},
  flipped=false, flipDynamically=false, disableClick=false, editable=false, showCardLabels=false,
  useFormatText=false, fontSize="default"
}
  ) {
  // States
  const [isFlipped, setIsFlipped] = useState(false);
  const [noFlip, setNoFlip] = useState(false);
  const [firstRender, setFirstRender] = useState(false);

  useEffect(() => {
    if (backSide === null) {
      setNoFlip(true);
    }
  }, [backSide]);

  useEffect(() => {
    const card = document.querySelector(".flash-card-inn");
    if (defaultSide === "back") {
      card.style.transform = "rotateY(180deg)";
      setIsFlipped(true);
    }
  }, [defaultSide]);

  useEffect(() => {
    const card = document.querySelector(".flash-card-inn");

    if (card) {
      card.style.transition = `all ${flipDuration}s`;
    }
  }, [flipDuration]);

  const flipCard = (e) => {
    let target = e.currentTarget;
    setIsFlipped(!isFlipped);

    if (!isFlipped) {
      target.style.transform = "rotateY(180deg)";
    } else {
      target.style.transform = "rotateY(0deg)";
    }

    onFlip();
  };

  useEffect(() => {
    if (flipDynamically) {
      const card = document.querySelector(".flash-card-inn");
      if (flipped) {
        flipCard({ currentTarget: card });
      } else if (!flipped && firstRender) {
        flipCard({ currentTarget: card });
      } else {
        setFirstRender(true);
      }
    }
  }, [flipped]);

  return (
    <div className={`${className} flash-card-main`}>
      <div className="flash-card-inn"
        style={{ backgroundColor: cardColor, color: textColor, width: fillScreen? "100%" : cardWidth, height: fillScreen? "100%" : cardHeight, zIndex: 1, ...sx}}
        onClick={(e) => !noFlip && !disableClick ? flipCard(e) : null}
      >
        <div className={`flash-card-items-front flash-items`}>
          {showCardLabels && 
            <p className="card-label">
              FRONT
            </p>
          }
          {typeof frontSide === "string" ?
            <h1 dangerouslySetInnerHTML={{ __html: formatText? formatText(convertNewlinesToBr(frontSide)) : convertNewlinesToBr(frontSide) }} 
              style={{ fontSize: fontSize === "default"? null : `${fontSize} !important`, textAlign: 'left', padding: '10px' }}
              id={`${fontSize}`}
            />
            :
            frontSide
          }
        </div>
        <div className={`flash-card-items-back flash-items`}>
          {showCardLabels && 
            <p className="card-label">
              BACK 
            </p>
          }
          {typeof backSide === "string" ?
            <h1 dangerouslySetInnerHTML={{ __html: formatText? formatText(convertNewlinesToBr(backSide)) : convertNewlinesToBr(backSide)}} 
              style={{ fontSize: fontSize === "default"? null : `${fontSize} !important`, textAlign: 'left', padding: '10px' }}
              id={`${fontSize}`}
            />
            :
            backSide
          }
        </div>
      </div>
    </div>
  );
};

function NavigationButton({ onLeft=()=>{}, onRight=()=>{}, color="#fff", sorting=false, sortingData=null }) {
  return (
    <div style={{ display:'flex' }}>
      {sorting && sortingData !== null? 
        <div
          className="navigation-button-left-counter"
          style={{
            height: "30px",
            width: "40px",
            padding: "5px",
            borderRadius: "20px",
            marginRight: '2vw',
            backgroundColor: "#ff7920",
            textAlign: "center",
            verticalAlign: "middle",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "20px",
          }}
        >
          <p>{sortingData.length >= 1? sortingData.filter(item => item === false).length : 0}</p>
        </div>
      :null}
      <div className="navigation-button-main">
        <Button
          className="navigation-button-left"
          onClick={(e) => onLeft(e)}
          sx={{
            color: color,
            backgroundColor: "#003687",
            transition: "all 0.3s ease",
            padding: "10px",
            paddingLeft: "15px",
            borderRadius: "20px",
            '&:hover':{
              transform: "scale(1.1)"
            }
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} fontSize={20} color={color} />
        </Button>
        <Button
          className="navigation-button-right"
          onClick={(e) => onRight(e)}
          sx={{
            color: color,
            backgroundColor: "#003687",
            marginLeft: "20px",
            transition: "all 0.3s ease",
            padding: "10px",
            paddingRight: "15px",
            borderRadius: "20px",
            '&:hover':{
              transform: "scale(1.1)"
            }
          }}
        >
          <FontAwesomeIcon icon={faArrowRight} fontSize={20} color={color} />
        </Button>
      </div>
      {sorting && sortingData !== null? 
        <div
        className="navigation-button-left-counter"
          style={{
            height: "30px",
            width: "40px",
            padding: "5px",
            borderRadius: "20px",
            marginLeft: '2vw',
            backgroundColor: "#59E8B5",
            textAlign: "center",
            verticalAlign: "middle",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "20px",
          }}
        >
          <p>{sortingData.length >= 1? sortingData.filter(item => item === true).length : 0}</p>
        </div>
      :null}
    </div>
  );
}

function SettingsPopup({ className, onOpen=()=>{}, onClose=()=>{}, open, onChange=()=>{}, sortButtonState, shuffleButtonState }) {
  const [firstTime, setFirstTime] = useState(true);

  useEffect(() => {
    if (open) {
      onOpen("lol");

      document.querySelector('.inner-main-view').classList.add('before');
      document.querySelector('.settings-popup-main').classList.remove('popup-move-out');
      document.querySelector('.settings-popup-main').classList.add('popup-move');
    } else {
      onClose("lol");

      document.querySelector('.inner-main-view').classList.remove('before');
      document.querySelector('.settings-popup-main').classList.remove('popup-move');
      if (!firstTime) {
        document.querySelector('.settings-popup-main').classList.add('popup-move-out');
      } else {
        setFirstTime(false);
      }
    }
  }, [open]);

  function InfoButton({ className, onClick=()=>{}, sx={} }){
    let resolution = '30px';

    return (
      <Button
        className={className}
        onClick={onClick}
        sx={{
          backgroundColor: "#00f",
          color: "#fff",
          borderRadius: "20px",
          maxWidth: resolution,
          minWidth: resolution,
          height: resolution,
          padding: "3px",
          boxSizing: "border-box",
          border: 'white 2px solid',
          transition: "all 0.3s ease",
          marginLeft: "5px",
          '&:hover': {
            backgroundColor: "#77f",
            transform: "scale(1.1)",
          },
          ...sx
        }}
      >
        <FontAwesomeIcon icon={faInfo} fontSize={20} style={{ margin: 0 }}/>
      </Button>
    )
  }

  return (
    <div className={`${className} settings-popup-main`}>
      <div className="settings-icons">
        <div className="settings-icon-inner">
          <Button
            className="settings-icon"
            sx={{
              backgroundColor: shuffleButtonState? `${secondaryColor} !important` : "#003687 !important",
            }}
            onClick={(e) => onChange(e,'shuffle')}
          >
            <FontAwesomeIcon icon={faShuffle} fontSize={40}/>
          </Button>

          <div className="settings-label-inner">
            <h2 className="settings-icon-label">
              Shuffle
            </h2>

            {/* <InfoButton /> */}
          </div>

          <p style={{ textAlign: 'center', margin: '0 20px 40px 20px' }}>
            Randomize the order of the flashcards
          </p>
        </div>

        <div className="settings-icon-inner">
          <Button
            className="settings-icon"
            sx={{
              backgroundColor: sortButtonState? `${secondaryColor} !important` : "#003687 !important",
            }}
            onClick={(e) => onChange(e,'sort')}
          >
            <FontAwesomeIcon icon={faFileCircleCheck} fontSize={40}/>
          </Button>

          <div className="settings-label-inner">
            <h2 className="settings-icon-label">
              Card Sorting
            </h2>

            {/* <InfoButton /> */}
          </div>

          <p style={{ textAlign: 'center', margin: '0 20px 0 20px' }}>
            Sort the flashcards into learned and learning by swiping to the right for learned and left for learning
          </p>
        </div>
      </div>
    </div>
  );

}

function FlashCardView(
  { data, className, fillScreen=false, cardWidth="55%", cardSides="front", previousComponent=<HomePage/>, cardSorting=false, showTopBar=true, sx={}, showNavigator=true,
    updateToCloud=true, cardFontSize="default", useCardWidth=false }
) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isBlurred, setIsBlurred] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState(null);

  const disableAutoLockRef = useRef(false);

  const [cardData, setCardData] = useState(null);
  const [setData, setSetData] = useState(null);
  const [oldData, setOldData] = useState(null);

  const [cardSortingT, setCardSorting] = useState(cardSorting);
  const [cardSortingD, setCardSortingData] = useState([]);
  const [sortingHistory, setSortingHistory] = useState([[]]);
  const [showButtons, setShowButtons] = useState(window.matchMedia("(max-width: 1500px)").matches? false : true);
  const [newCardWidth, setNewCardWidth] = useState(window.matchMedia("(max-width: 1090px)").matches? window.matchMedia('(max-width: 800px)').matches? "90%": "65%": "55%");
  const [cardUnshuffledData , setCardUnshuffledData] = useState(deepCopy(data.cards));

  const [isKeyDown, setIsKeyDown] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [deletePopupOpen, setShopDeletePopup] = useState(false);
  const [editorPickerOpen, setEditorPickerOpen] = useState(false);
  const [shuffleOn, setShuffleOn] = useState(data.shuffleCards);

  // calculate flashcard percentage
  if (cardSortingT) {
    if (cardSortingD.length !== 0) {
      var endPercentage = cardSortingD.filter(item => item === true).length / cardSortingD.length * 100;
    }
  }

  const shuffleArray = (array, state) => {
    let shuffledArray = deepCopy(array); // Create a copy of the array

    if (state) {
      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
      }
    }
    return shuffledArray;
  };

  const onRight = (e) => {
    try {
      if (!(currentSlide === cardData.length - 1)) {
        const newSorting = [...cardSortingD];
        newSorting[currentSlide] = false;
        setCardSortingData(newSorting);
        setSortingHistory([...sortingHistory, newSorting]);
      }
    } catch (error) {
      console.log("Error setting sorting data", error);
    }
  };

  const onLeft = (e) => {
    if (sortingHistory.length > 1) {
      const newHistory = sortingHistory.slice(0, -1);
      setSortingHistory(newHistory);
      setCardSortingData(newHistory[newHistory.length - 1]);
    }
  };

  // Reset Swiper
  const resetSwiper = () => {
    disableAutoLockRef.current = true;

    if (swiperInstance) {
      swiperInstance.slideTo(0);
      setCardSortingData([]);
      setSortingHistory([[]]);
    } else {
      console.log('Swiper instance is not set');
    }

    setTimeout(() => {
      disableAutoLockRef.current = false;
    }, 5);
  };

  // Move swiper left or right
  const moveSwiper = (direction, swiperIndex) => {
    disableAutoLockRef.current = true;

    if (swiperInstance) {
      if (direction === "left") {
        swiperInstance.slidePrev();
        onLeft();

      } else if (direction === "right") {
        swiperInstance.slideNext();
        onRight();

      }
    } else {
      console.log('Swiper instance is not set');
    }

    setTimeout(() => {
      disableAutoLockRef.current = false;
    }, 5);
  };

  // Check if item is a div with 'end' id
  const isEndDiv = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const endDiv = doc.getElementById('end');
    return endDiv !== null;
  };

  // Enable card sorting for the flashcard set
  const toggleCardSorting = () => {
    setCardSorting(!cardSortingT);
    data.cardSorting = !cardSortingT;

    resetSwiper();

    if (cardSortingT) {
      data.cards.pop();

      for (let i = 0; i < data.cards.length; i++) {
        if (isEndDiv(data.cards[i].front)) {
          data.cards.splice(i, 1);
        }
      }
    } else {
      if (data.cards[data.cards.length - 1] !== 'end') {
        data.cards.push('end');
      }
    }
  }

  // TODO: Fix the buttons not working on keydown
  // TODO: implement font size to flashcards
  // TODO: Fix the flashcard width not changing

  // Save the progress data and other data to DB
  const editSpecialData = (data, swiperIndex) => {
    try {
      if (!updateToCloud) {
        return;
      }

      let cards = data.cards;

      for (let i = 0; i < cards.length; i++) {
        if (cards[i] === 'end' || isEndDiv(cards[i].front)) {
          cards.splice(i, 1);
        }
      }

      let dataSave = {
        ...data,
        cards: data.cards,
        lastOpened: formatDate(new Date()),
        progress: swiperIndex + 1 !== data.cards.length? swiperIndex : 0,
        timesViewed: data.timesViewed + 1,
        shuffleCards: shuffleOn,
      }
      if (oldData === null) {
        modifyFlashcardField(data.id, 'lastOpened', dataSave.lastOpened);
        modifyFlashcardField(data.id, 'progress', dataSave.progress);
        modifyFlashcardField(data.id, 'timesViewed', dataSave.timesViewed);
        modifyFlashcardField(data.id, 'shuffleCards', dataSave.shuffleCards);
      } else {
        if (oldData.progress !== dataSave.progress) {
          modifyFlashcardField(data.id, 'progress', dataSave.progress);
        } 
        if (oldData.shuffleCards !== dataSave.shuffleCards) {
          modifyFlashcardField(data.id, 'shuffleCards', dataSave.shuffleCards);
        } 
        if (oldData.timesViewed !== dataSave.timesViewed) {
          modifyFlashcardField(data.id, 'timesViewed', dataSave.timesViewed);
        } 
        if (oldData.lastOpened !== dataSave.lastOpened) {
          modifyFlashcardField(data.id, 'lastOpened', dataSave.lastOpened);
        }
      }


      setOldData(dataSave);
    } catch (error) {
      console.log("Error editing special flashcard data", error);
    }
  }

  // Delete the flashcard set
  const deleteFlashcardSet = () => {
    try {
      removeFlashcardSet(data.id).then((response) => {
        if (response.success) {
          toast.success("Flashcard set deleted successfully!");
          setTimeout(() => {
            route(<HomePage />);
          }, 1000);
        } else {
          toast.error("Error deleting flashcard set");
        }
      });
    } catch (error) {
      console.log("Error deleting flashcard set", error);
    }
  }

  // toggle settings open again
  const toggleSettingsOpenEV = () => {
    setSettingsOpen((prev) => !prev);
  }

  const toggleFlip = () => {
    setIsFlipped(fl => !fl);
  }

  // Keyboard events
  const handleKeyDown = useCallback((ev) => {
    if (isKeyDown) return; // If the key is already being processed, do nothing

    setIsKeyDown(true) // Mark the key as being processed
    editSpecialData(deepCopy(data), currentSlide);

    console.log("Key: ", ev.key);

    if (ev.key === "s") {
      toggleSettingsOpenEV();
    } else if (ev.key === "r") {
      resetSwiper();
    } else if (ev.key === "l") {
      // toggleCardSorting();
    } else if (ev.key === "Escape") {
      setSettingsOpen(false);
      setShopDeletePopup(false);
      setEditorPickerOpen(false);

      if (document.querySelector('.side-bar')) {
        document.querySelector('.side-bar').classList.remove('move');
      }

      if (document.querySelector('.inner-main-view')) {
        document.querySelector('.inner-main-view').classList.remove('move');
      }

      route(<HomePage />);
    } else if (ev.key === "a" || ev.key === "ArrowLeft") {
      moveSwiper("left", currentSlide);
    } else if (ev.key === "d" || ev.key === "ArrowRight") {
      moveSwiper("right", currentSlide);
    } else if (ev.key === "Enter" || ev.key === " ") {
      toggleFlip();
      console.log("Flipping card...", isFlipped);
    }
  }, [cardSortingT, currentSlide, swiperInstance]);

  // Reset the key processing flag when the key is released
  const handleKeyUp = () => {
    setIsKeyDown(false);
  };

  useEffect(() => {
    console.log('Cards in history: ', cardUnshuffledData);
  }, [cardUnshuffledData]);

  // Refracture Data
  useEffect(() => {
    try {
      if (cardSortingT && data.cards[data.cards.length - 1] !== 'end') {
        data.cards.push('end');
      }

      setCardData(data.cards);
      setOldData(data);

      setSetData({
        title: data.title,
        description: data.description,
        author: data.author,
        progress: data.progress,
        date: data.date,
      });
      
      editSpecialData(deepCopy(data), data.progress);
    } catch (error) {
      console.log("Error setting data", error);
      setCardData(null);
      setSetData(null);
    }

    window.addEventListener("resize", (ev) => {
      if (window.matchMedia("(max-width: 1500px)").matches) {
        setShowButtons(false);
      } else {
        setShowButtons(true);
        document.querySelector('.side-bar').classList.remove('move');
        document.querySelector('.inner-main-view').classList.remove('move');
      }

      if (window.matchMedia("(max-width: 1090px)").matches) {
        if (window.matchMedia('(max-width: 800px)').matches) {
          setNewCardWidth("90%");
        } else {
          setNewCardWidth("65%");
        }
      } else {
        setNewCardWidth("55%");
      }
    })

    window.addEventListener("click", (ev) => {
      if (document.querySelector('.side-bar') && document.querySelector('.inner-main-view')) {
        if (document.querySelector('.side-bar').classList.contains('move') && !ev.target.classList.contains('side-bar') && ev.target.classList.contains('inner-main-view')) {
          document.querySelector('.side-bar').classList.remove('move');
          document.querySelector('.inner-main-view').classList.remove('move');
          setSettingsOpen(false);

          console.log("Clicked Event Listener");
        } else {
          // pass
        }
      }
    })

    return () => {
      console.log("Removing event listeners...");

      window.removeEventListener("resize", (ev) => {});
      window.removeEventListener("click", (ev) => {});
    }

  }, []);

  useEffect(() => {
    window.addEventListener("keydown", (ev) => {
      handleKeyDown(ev);
    });

    window.addEventListener("keyup", (ev) => {
      handleKeyUp(ev);
    });

    return () => {
      window.removeEventListener("keydown", (ev) => {});
      window.removeEventListener("keyup", (ev) => {});
    }
  }, [ swiperInstance ]);

  const unFlip = () => {
    const cards = document.querySelectorAll(`.flash-card-inn`);
    if (cards) {
      for (let card of cards) {
        card.style.transform = cardSides === "front"?"rotateY(0deg)" : "rotateY(180deg)";
      }
    }
  };

  useEffect(() => {
    if (shuffleOn) {
      setCardUnshuffledData(deepCopy(data.cards));
      setCardData(c => shuffleArray(c, shuffleOn));
      let newData = {
        ...deepCopy(data),
        shuffleCards: true,
      }
      editSpecialData(newData, currentSlide);
    } else {
      if (cardUnshuffledData.length !== 0) {
        setCardData(cardUnshuffledData);
        let newData = {
          ...deepCopy(data),
          shuffleCards: false,
        }
        editSpecialData(newData, currentSlide);
      }
    }
  }, [shuffleOn]);

  if (cardData === null || setData === null) {
    return (
      <Loader primaryColor={accentColor} scale={2} />
    )
  }

  setTimeout(() => {
    setProgressPercentage(((currentSlide + 1) / cardData.length) * 100);
  }, 1);

  const buttonSX = {
    position: 'absolute',
    top: '10px',
    right: '250px',
    backgroundColor: '#003687',
    color: "white",
    borderRadius: "10px",
    transition: "all 0.3s ease",
    height: "50px",
    width: "50px",
    '&:hover': {
      backgroundColor: secondaryColor,
      transform: "scale(1.1)",
    },
  }

  // TODO: Fix the Main Flashcard Swiper doing glitchy things
  return (
    <div className={`${className} flash-card-V-main`} style={{
      ...{ width: fillScreen?"100vw" : "auto", height: fillScreen?"100vh" : "auto", overflow: fillScreen?"hidden" : "auto"},
      ...sx
    }}>
      <div className="inner-main-view">
        {showTopBar? <div className="inner-inn-main-view">
          <div className="side-bar">
            <Button
              sx={{
                ...buttonSX,
                position: 'unset',
                backgroundColor: deletePopupOpen? secondaryColor : '#003687',
              }}
              onClick={() => {
                setShopDeletePopup(!deletePopupOpen);
              }}
            >
              <FontAwesomeIcon icon={faTrash} fontSize={20}/>
            </Button>
            <Button
              sx={{
                ...buttonSX,
                position: 'unset',
                backgroundColor: editorPickerOpen? secondaryColor : '#003687',
              }}
              onClick={() => { setEditorPickerOpen(!editorPickerOpen) }}
            >
              <FontAwesomeIcon icon={faPencil} fontSize={20}/>
            </Button>
            <Button
              sx={{
                ...buttonSX,
                position: 'unset',
                backgroundColor: cardSortingT? secondaryColor : '#003687',
              }}
              onClick={() => {
                toggleCardSorting();
                document.querySelector('.side-bar').classList.remove('move');
                document.querySelector('.inner-main-view').classList.remove('move');
                setSettingsOpen(false);
              }}
            >
              <FontAwesomeIcon icon={faListCheck} fontSize={20}/>
            </Button>
            <Button
              sx={{
                ...buttonSX,
                position: 'unset',
          
              }}
              onClick={() => {
                resetSwiper();
                document.querySelector('.side-bar').classList.remove('move');
                document.querySelector('.inner-main-view').classList.remove('move');
                setSettingsOpen(false);
              }}
            >
              <FontAwesomeIcon icon={faRepeat} fontSize={20}/>
            </Button>
            <Button
              sx={{
                ...buttonSX,
                position: 'unset',
                backgroundColor: settingsOpen? secondaryColor : '#003687',
              }}
              onClick={() => { setSettingsOpen(!settingsOpen) }}
            >
              <FontAwesomeIcon icon={faGears} fontSize={20}/>
            </Button>
          </div>
          <h2 style={{ marginTop: '20px', marginBottom: '20px' }}>{setData.title}</h2>
          {showButtons?
            <><Button
              sx={{
                ...buttonSX,
                right: '10px',
                backgroundColor: deletePopupOpen? secondaryColor : '#003687',
              }}
              onClick={() => {
                setShopDeletePopup(!deletePopupOpen);
              }}
            >
              <FontAwesomeIcon icon={faTrash} fontSize={20}/>
            </Button>
            <Button
              className="edit-btn-side"
              sx={{
                ...buttonSX,
                right: '90px',
                backgroundColor: editorPickerOpen? secondaryColor : '#003687',
              }}
              onClick={() => { setEditorPickerOpen(!editorPickerOpen) }}
            >
              <FontAwesomeIcon icon={faPencil} fontSize={20}/>
            </Button>
            <Button
              sx={{
                ...buttonSX,
                right: '170px',
                backgroundColor: cardSortingT? secondaryColor : '#003687',
              }}
              onClick={() => {
                toggleCardSorting();
                document.querySelector('.side-bar').classList.remove('move');
                document.querySelector('.inner-main-view').classList.remove('move');
                setSettingsOpen(false);
              }}
            >
              <FontAwesomeIcon icon={faListCheck} fontSize={20}/>
            </Button>
            <Button
              sx={{
                ...buttonSX,
                right: '250px',
              }}
              onClick={() => {
                resetSwiper();
                document.querySelector('.side-bar').classList.remove('move');
                document.querySelector('.inner-main-view').classList.remove('move');
                setSettingsOpen(false);
              }}
            >
              <FontAwesomeIcon icon={faRepeat} fontSize={20}/>
            </Button>
            <Button
              sx={{
                ...buttonSX,
                right: '330px',
                backgroundColor: settingsOpen? secondaryColor : '#003687',
              }}
              onClick={() => { setSettingsOpen(!settingsOpen) }}
            >
              <FontAwesomeIcon icon={faGears} fontSize={20}/>
            </Button></>:
            <Button
              sx={{
                ...buttonSX,
                right: '10px'
              }}
            >
              <FontAwesomeIcon icon={faBars} fontSize={20}
                onClick={(e) => {
                  document.querySelector('.side-bar').classList.toggle('move');
                  document.querySelector('.inner-main-view').classList.toggle('move');
                  setSettingsOpen(false);
                }}
              />
            </Button>
          }
        </div>:null}
        <div
          className="progress-bar-main"
          style={{
            width: "100%",
            height: "5px",
            backgroundColor: "#003687",
            marginBottom: "10px",
          }}
        >
          <div 
            className="progress-bar" 
            style={{ 
              width: `${progressPercentage}%`, 
              height: '5px', 
              backgroundColor: '#f1f1f1', 
              marginBottom: '10px', 
              transition: 'width 0.5s'
            }}
          />
        </div>
        <Swiper
          className="flashSwiper"
          slidesPerView={1}
          effect={"cards"}
          grabCursor
          centeredSlides
          virtual={{
            addSlidesAfter: 2,
            addSlidesBefore: 2,
          }}
          modules={[EffectCards, Virtual]}
          style={{
            height: "80%",
            width: newCardWidth,
          }}
          initialSlide={data.progress}
          onSwiper={(e) => setSwiperInstance(e)}
          onSlideChange={(swiper) => {
            unFlip();
            setCurrentSlide(swiper.activeIndex);
            setIsBlurred(swiper.activeIndex === cardData.length - 1);
            editSpecialData(deepCopy(data), swiper.activeIndex);

            // Detect direction and always move forward
            if (cardSortingT && !disableAutoLockRef.current) {
              let sorted;

              if (swiper.activeIndex < swiper.previousIndex) {
                swiper.slideNext();
                swiper.slideNext();

                // Set the slide for sorting FOR LEARNED CARDS
                sorted = cardSortingD;
                if (data.cards[swiper.activeIndex] !== 'end') {
                  sorted[swiper.activeIndex] = true;
                }
              } else if (swiper.activeIndex > swiper.previousIndex) {
                // Set the slide for sorting FOR LEARNING
                sorted = cardSortingD;
                if (data.cards[swiper.activeIndex] !== 'end') {
                  sorted[swiper.activeIndex] = false;
                }
              }
            }
          }}
        >
          {cardData.map((item, index) => {
            const shouldBlur = isBlurred && index < cardData.length - 1;
            return (
              <SwiperSlide key={index} virtualIndex={index} className={`swiper-slide ${shouldBlur ? 'blur' : ''}`}>
                <FlashCard
                  useFormatText
                  fontSize={cardFontSize}
                  frontSide={item !== 'end' ? convertNewlinesToBr(item.front) : 
                  <div id="end" style={{display: 'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width:'100%'}}>
                    <h1>End of your set!</h1>
                    <CircularProgress 
                      percentage={endPercentage} 
                      width="200px"
                      height="200px"
                      textColor="#f1f1f1"
                      trailColor="#FF7920"
                      pathColor="#59E8B5"
                    />
                    <Button>
                      View Statistics
                    </Button>
                    <div style={{display:'flex', gap:'15px', width:'100%', justifyContent: 'center', alignItems: 'center', marginTop: '5px'}}>
                      <div style={{ 
                        backgroundColor:'#59E8B5cc', 
                        color: '#fff', 
                        width:'40%', 
                        borderRadius: '20px', 
                        padding: '5px', 
                        textAlign:'center',
                        verticalAlign: 'middle',
                      }}>
                        <h3 style={{ margin: 0 }}>
                          correct
                        </h3>
                      </div>

                      <div style={{ 
                        backgroundColor:'#FF7920cc', 
                        color: '#fff', 
                        width:'40%', 
                        borderRadius: '20px', 
                        padding: '5px', 
                        textAlign:'center',
                        verticalAlign: 'middle',
                      }}>
                        <h3 style={{ margin: 0 }}>
                          incorrect
                        </h3>
                      </div>
                    </div>
                  </div>}
                  backSide={item !== 'end' ? convertNewlinesToBr(item.back) : null}
                  fillScreen={item !== 'end' ? true : false}
                  defaultSide={cardSides}
                  cardHeight={item === 'end' ? '60%' : undefined}
                  cardWidth={item === 'end' ? (useCardWidth? cardWidth: '60%') : undefined}
                  sx={item === 'end' ?
                    {
                      border: "2px solid #f1f1f1",
                      cursor: "default"
                    }
                    :
                    {}
                  }
                  flipped={isFlipped}
                />
              </SwiperSlide>
            );
          })}
          {/* <SwiperSlide className="swiper-slide">
            Test 1
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            Test 2
          </SwiperSlide> */}
        </Swiper>
        {showNavigator? <NavigationButton 
          onLeft={() => { moveSwiper("left", currentSlide) }} 
          onRight={() => { moveSwiper("right", currentSlide) }} 
          sorting={cardSortingT}
          sortingData={cardSortingD}
        />: null}
        {showTopBar? <BackButton toComponent={previousComponent} /> : null}
        <SettingsPopup 
          open={settingsOpen} 
          sortButtonState={cardSortingT} 
          shuffleButtonState={shuffleOn}
          onChange={(e, type) => {
            if (type === 'shuffle') {
              setShuffleOn(!shuffleOn);

            } else if (type === 'sort') {
              toggleCardSorting();
            }
          }}
        />

        <AreYouSurePopup 
          open={deletePopupOpen} 
          message={"Are you sure you want to delete this flashcard set?"} 
          onConfirm={() => { deleteFlashcardSet(); setShopDeletePopup(false) }} 
          onCancel={() => { setShopDeletePopup(false) }} 
          alert 
          className={'delete'} 
        />

        <AreYouSurePopup 
          open={editorPickerOpen} 
          message={"Choose an editor to edit this flashcard set"} 
          onConfirm={() => { 
            setEditorPickerOpen(false); 
            toast.info("Opening editor..."); 
            setTimeout(() => {
              route(<SimpleEditor flashcardData={data} editMode />);
            }, 1000); 
          }} 
          onCancel={() => { setEditorPickerOpen(false); toast.info("Coming soon...") }} 
          messageAlert 
          confirmText="Basic Editor"
          cancelText="Advanced Editor"
          className={'edit'} 
        />

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        pauseOnHover={false}
        closeOnClick
        rtl={false}
        theme={getTheme()}
      />
      </div>
    </div>
  );
}

export default FlashCardView;
export { FlashCard };