import React, { Suspense, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faPlus } from "@fortawesome/free-solid-svg-icons";

// Import the styles
import "./homePage.scss";
import 'swiper/css';

// Import useful components
import { route } from "../../utils/router";
import { accentColor } from "../../utils/projectColors";
import { gradeFlashcards } from "../../scripts/flashcardGrader";
import { checkType } from "../../utils/algorithms";
import { adjustOnScreenSize, isMobileDevice } from "../../utils/detectDevice";
import { listFlashcardsByUserID } from "../../scripts/databaseManager";
import { getCurrentUser, handleGoogleSignInRedirectResult } from "../../scripts/accountManager";
import { auth } from "../../config/firebaseConfig.js";

import FlashCardView from "../Flashcard-view/flashCardView";
import Loader from "../../components/Loader/Loader";
import Navbar from "../../components/Navbar/Navbar";
import LandingPage from "../landing-page/_layout";
import FlashCardEditor from "../Flash-card-editor/flashCardEditor";
import SimpleEditor from "../Flash-card-editor/flashCardEditorSimple.jsx";


function HomePage() {
  const [recentSets, setRecentSets] = useState([]);
  const [mySets, setMySets] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [slideCount, setSlideCount] = useState(!isMobileDevice()? adjustOnScreenSize()[0] + 1 : adjustOnScreenSize()[0]);

  useEffect(() => {
    // Check if the user is signed in 1
    const checkUserStatus = async () => {
      const user = await getCurrentUser();
      console.log('Is user signed in? ', user.status);

      if (!user.status) {
        route(<LandingPage />);
        console.log('User not signed in');
      } else {
        console.log('User signed in');
        // Fetch the data from the database
        listFlashcardsByUserID(user.user.uid).then((flashcards) => {
          console.log("Flashcards loaded!: ", flashcards);

          if (flashcards === null) {
            flashcards = [];
          }

          // Grade the flashcards
          const gradedFlashcards = gradeFlashcards(flashcards);

          // Get the recent sets
          const recentSets = gradedFlashcards.slice(0, 10);

          setRecentSets(recentSets);
          setMySets(flashcards);

          setIsLoaded(true);
        });
      }
    };

    // Check if the user is signed in 2
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        checkUserStatus();
      } else {
        route(<LandingPage />);
      }
    });

    // Setting an event listener for window resize
    const handleResize = () => {
      setSlideCount(!isMobileDevice() ? adjustOnScreenSize()[0] + 1 : adjustOnScreenSize()[0]);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      unsubscribe();
      console.log('Removing event listener');
    };
  }, []);

  useEffect(() => {
    handleGoogleSignInRedirectResult().then(
      (result) => {
        console.log('Redirect result: ', result);
      }
    ).catch((error) => {
      console.error('Error handling redirect result: ', error);
    });
  }, []);

  const quickViewFolder = (e, folder) => {
    console.log('Quick view folder:', folder);

    // Create the box element
    const box = document.createElement('div');
    box.className = 'quick-view-box';
    box.style.width = '500px';
    box.style.height = '300px';
    box.style.position = 'absolute';
    box.style.backgroundColor = 'white';
    box.style.border = '1px solid black';
    box.style.borderRadius = '10px';
    box.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
    box.style.zIndex = '1000';

    // Get the position of the triggering element
    const rect = e.target.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    // Determine if there is enough space to open the box below or above
    if (rect.bottom + 300 <= viewportHeight) {
      // Open below
      box.style.top = `${rect.bottom + window.scrollY}px`;
    } else {
      // Open above
      box.style.top = `${rect.top - 300 + window.scrollY}px`;
    }

    // Set the left position
    box.style.left = `${rect.left + window.scrollX}px`;

    // Add the box to the main div
    const mainDiv = document.querySelector('.home-page-main');
    if (mainDiv) {
      if (!document.querySelector('.quick-view-box')) {
        mainDiv.appendChild(box);
      } else {
        console.log('Box already exists');
      }
    } else {
      console.error('Main div not found');
    }

    // Optionally, you can add content to the box
    box.innerHTML = `<p>Folder: ${folder}</p>`;

    // Remove the box when clicking outside
    const removeBox = (event) => {
      if (!box.contains(event.target) && !currentTarget.contains(event.target)) {
        if (mainDiv) {
          if (document.querySelector('.quick-view-box')) {
            mainDiv.removeChild(box);
          } else {
            console.log('Box not found');
          }
        } else {
          console.error('Main div not found');
        }
        document.removeEventListener('click', removeBox);
      }
    };

    // Capture the currentTarget using a closure
    const currentTarget = e.currentTarget;
    document.addEventListener('click', removeBox);
  };


  const loadSets = (sets) => {
    let res = [];

    if (sets.length === 0) {
      return (
        <SwiperSlide className="display-swiper">
          <div
            className="set-display"
            onClick={(e) => route(<SimpleEditor />, 'strict')}
          >
            <FontAwesomeIcon icon={faPlus} fontSize='60px' />
          </div>
        </SwiperSlide>
      )
    }

    sets = [{}, ...sets]

    for (let j = 1; j < sets.length ;j++) {
      let set = sets[j];
      if (checkType(set) === 'object') {
        res.push(
          <SwiperSlide className="display-swiper" key={j}>
            <div
              className="set-display"
              key={j}
              onClick={(e) => route(<FlashCardView data={set} fillScreen />)}
            >
              <h2 className="title-h2">{set.title}</h2>
              <p className="title-p">{set.description}</p>
              <div style={{ display:'flex', justifyContent:'space-between' }}>
                <p><br/>By <em>{set.author}</em></p>
                <p><br/><strong>{set.date}</strong></p>
                
              </div>
            </div>
          </SwiperSlide>
        )
      } else if (checkType(set) === "array") {
        res.push(
          <SwiperSlide className="display-swiper">
            <div
              className={`set-display folder-${j}`}
              key={j}
              id={`folder-${j}`}
              style={{
                backgroundColor: set[0].bgColor,
                color: set[0].color,
              }}
              onClick={(e) => quickViewFolder(e, set)}
            >
              <FontAwesomeIcon icon={faFolder} fontSize='60px' />
              <h2 className='title-h2'>{set[0].title}</h2>
              <div style={{ display:'flex', justifyContent:'space-between' }}>
                <p><br/><em>{set[0].author}</em></p>
                <p><br/><strong>{set[0].date}</strong></p>
                
              </div>
            </div>
          </SwiperSlide>
        )
      }
    }

    return res;
  }

  if (!isLoaded) {
    return <Loader primaryColor={accentColor} scale={2} />;
  }

  return (
    <Suspense fallback={<Loader primaryColor={accentColor} scale={2} />}>
      <div className="home-page-main">
        <Navbar />
        <h4>Recent sets</h4>
        <div className="recent-sets">
          <Swiper
            className="recent-sets-swiper"
            slidesPerView={slideCount}
            grabCursor
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            {recentSets.length === 0 ?
              <SwiperSlide className="display-swiper">
                <div
                  className="set-display"
                  onClick={(e) => route(<SimpleEditor />, 'strict')}
                >
                  <FontAwesomeIcon icon={faPlus} fontSize='60px' />
                </div>
              </SwiperSlide>
              :
              recentSets.map((set, index) => {
                return (
                  <SwiperSlide className="display-swiper" key={set.id}>
                    <div className="set-display" key={set.title} onClick={(e) => route(<FlashCardView data={set} cardSorting={set.cardSorting} fillScreen />)}>
                      <h2 className="title-h2">{set.title}</h2>
                      <p className="title-p">{set.description}</p>
                      <div style={{ display:'flex', justifyContent:'space-between' }}>
                        <p><br/>By <em>{set.author}</em></p>
                        <p><br/><strong>{set.date}</strong></p>
                        
                      </div>
                    </div>
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
        </div>
        <h4>My sets</h4>
        <div>
          <Swiper
            className="my-sets-swiper"
            slidesPerView={slideCount}
            grabCursor
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            {loadSets(mySets)}
          </Swiper>
        </div>
      </div>
    </Suspense>
  );
}

export default HomePage;