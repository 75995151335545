import { collection, getDocs, addDoc, deleteDoc } from "firebase/firestore";
import { firestore, doc, setDoc } from "../config/firebaseConfig.js";
import { formatDate, jsxToString, parseToJSX } from "../utils/algorithms";
import { getAllFlashcards } from '../assets/exampleFlashcards/flashcards.js';
import { uploadFile } from "./storageManager";
import { query, where } from "firebase/firestore";
import { flashcard } from "../assets/exampleFlashcards/fromApp.js";

// TODO: minimize read/write operations to the database over the entire app

// Read all flashcards from the database
async function listFlashcards() {
  try {
    const flashcards = [];
    const flashNew = [];

    const flashcardsCollection = await getDocs(collection(firestore, "flashcards"));

    flashcardsCollection.forEach((doc) => {
      flashcards.push({
        ...doc.data(),
        id: doc.id,
      });
    });

    for (let flashcard of flashcards) {
      flashcard = parseToJSX(flashcard);
      flashNew.push(flashcard);
    }

    return flashNew;
  } catch (error) {
    console.error("Error listing flashcards: ", error);
  }
}

// Read all flashcards from a specific user from the database
async function listFlashcardsByUserID(uid) {
  try {
    const flashcards = [];
    const flashNew = [];

    // Reference to the collection
    const flashcardCollection = collection(firestore, 'flashcards'); 
    // Create a query against the collection
    const q = query(flashcardCollection, where('authorUID', '==', uid));

    // Execute the query
    const querySnapshot = await getDocs(q);

    // Check if any documents were found
    if (!querySnapshot.empty) {
      // Get the flashcards filtered by uid
      querySnapshot.docs.forEach((doc) => {
        flashcards.push({
          ...doc.data(),
          id: doc.id,
        });
      });
  
      for (let flashcard of flashcards) {
        flashcard = parseToJSX(flashcard);
        flashNew.push(flashcard);

        saveFlashcardSet(flashcard, flashcard.id);
      } 
  
      return flashNew;
    } else {
      console.log('No flashcards found with the specified uid.');
      return null;
    }
  } catch (error) {
    console.error("Error listing flashcards: ", error);
  }
}

// Modify a field of a flashcard
export const modifyFlashcardField = async (id, field, value) => {
  try {
    const docRef = doc(firestore, 'flashcards', id);
    await setDoc(docRef, { [field]: value }, { merge: true });

    return { error: null, success: true };
  } catch (error) {
    console.error("Error modifying flashcard field: ", error);
    return { error: error, success: false };
  }
} 

// Save the flashcard set to the database
async function saveFlashcardSet(flashcardSet, id = null) {
  try {
    let docRef;

    if (flashcardSet.cards.length === 0) {
      console.error("Error adding flashcard set: ", "No cards in the set.");
      return;
    }

    for (let k = 0; k < flashcardSet.cards.length; k++) {
      if (flashcardSet.cards[k] === "end") {
        flashcardSet.cards.splice(k, 1);
      }
    }

    flashcardSet = jsxToString(flashcardSet); // convert jsx to string 

    if (id === null) {
      docRef = await addDoc(collection(firestore, "flashcards"), flashcardSet);


    } else {
      docRef = await setDoc(doc(firestore, "flashcards", id), flashcardSet);
    }

    return docRef;
  } catch (error) {
    console.error("Error adding flashcard set: ", error);
  }
}

// Get the id of the flashcard set
const getIdFromFlashcard = async (flashcard) => {
  let allFlashcards = await listFlashcards();

  for (let card of allFlashcards) {
    if (card === flashcard) {
      return card.id;
    }
  }
}

// Remove the flashcard set from the database
const removeFlashcardSet = async (id) => {
  try {
    const docRef = doc(firestore, 'flashcards', id);
    await deleteDoc(docRef);

    return { error: null, success: true };
  } catch (error) {
    console.error("Error removing flashcard set: ", error);
    return { error: error, success: false };
  }
}

// 11, 8, 6, 2, 1

function generateFilesFromDictionaries(dictList) {
  return dictList.map((dict, index) => {
    const jsonString = JSON.stringify(dict);
    const file = new File([jsonString], `file${index}.flsh`, { type: 'application/json' });
    return file;
  });
}

const flashes = getAllFlashcards();

for (let i = 0; i < flashes.length; i++) {
  if (i !== 6) {
    // saveFlashcardSet(flashes[i]);
  }
}

// TODO: Lower the firebase writes amount

// saveFlashcardSet(flashes[5], "I0SusV8ZbN7V3erlBqN4");

// & saveFlashcardSet(flashcard)

// var collections = await listFlashcards();
// var flashcards = generateFilesFromDictionaries(collections);

// for (let flashcard of flashcards) {
//   uploadFile(flashcard).then((fileUrls) => {
//     console.log('Uploaded file URLs:', fileUrls);
//   });
// }

export { listFlashcards, saveFlashcardSet, getIdFromFlashcard, removeFlashcardSet, generateFilesFromDictionaries, listFlashcardsByUserID };