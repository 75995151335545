import React, { Suspense, useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faFileCirclePlus, faFileImport, faFilePen, faFolder, faFolderPlus, faImages, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';

import Loader from '../../components/Loader/Loader';
import BackButton from '../../components/BackButton/BackButton';
import FlashCardView from '../Flashcard-view/flashCardView';

import './fileView.scss';

import { accentColor } from '../../utils/projectColors';
import { listFlashcardsByUserID } from '../../scripts/databaseManager';
import { route } from '../../utils/router';
import { getCurrentUser } from '../../scripts/accountManager';
import NiceButton from '../../components/NiceButton/_layout';
import SimpleEditor from '../Flash-card-editor/flashCardEditorSimple';
import { isMobileDevice } from '../../utils/detectDevice';
import { parseFLSHintoObj } from '../../scripts/flashcardEncoder';
import { getTheme } from '../../utils/projectColors';

function FileView({ className }) {
  const [flashcardData, setFlashcardData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [nestedFiles, setNestedFiles] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [importOpen, setImportOpen] = useState(false);

  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // TODO: implement opening history for folders

  // Generate a recursive file structure from flashcards
  const generateRecursiveFileStructure = (flashcards) => {
    const files = [];

    const generateForOne = (flashcard, startIndex = 0) => {
      const path = flashcard.path.split('/');
      for (let i = 0; i < path.length; i++) {
        if (path[i] === '') {
          path.splice(i, 1);
          i--;
        }
      }
      
      path.unshift('/');
      path.push('');
      let currentPath = [];

      for (let index = 0; index < path.length; index++) {
        let folderName = path[index];

        if (index === startIndex) {
          if (index === path.length - 1) {
            currentPath.push({
              type: 'file',
              title: flashcard.title,
              data: flashcard,
              path: flashcard.path + `/${flashcard.title}.flsh`,
            })

            return currentPath;
          } else {
            currentPath.push({
              type: 'folder',
              title: folderName,
              data: generateForOne(flashcard, index + 1),
              path: path.slice(0, index + 1).join('/').slice(1) === ''? '/' : path.slice(0, index + 1).join('/').slice(1),
            })
          }
        } else {
        }
      }

      return currentPath;
    }

    const mergeFile = (files) => {
      const merged = [];

      files.forEach((file) => {
        if (file.type === 'file') {
          merged.push(file);
        } else if (file.type === 'folder'){
          let found = false;

          for (let mergedFile of merged) {
            if (mergedFile.type === 'folder' && mergedFile.title === file.title) {
              mergedFile.data.push(...file.data);
              found = true;
              break;
            }
          }

          if (!found) {
            merged.push(file);
          }
        }
      });

      return merged;
    }

    const mergeFiles = (files) => {
      if (files.length === 0) {
        return [];
      }

      for (let h = 0; h < files.length; h++) {
        for (let i = 0; i < files.length; i++) {
          if (files[i].type === 'folder') {
            files[i].data = mergeFiles(files[i].data);
          }
        }
      }

      return mergeFile(files);
    }

    const sortByName = (files) => {
      return files.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    // merge and remove duplicate folders into one path
    const mergeDuplicates = (files) => {
      let merged = [];
      let mergedNames = new Set();
    
      const mergeRecursive = (file) => {
        if (!mergedNames.has(file.title)) {
          mergedNames.add(file.title);
    
          // Find all files with the same title
          const duplicates = files.filter(f => f.title === file.title && f !== file);
    
          // Merge data from duplicates
          if (Array.isArray(file.data)) {
            for (const duplicate of duplicates) {
              if (Array.isArray(duplicate.data)) {
                file.data.push(...duplicate.data);
              }
            }
            
            // Recursively merge duplicates in nested data
            file.data = mergeDuplicates(file.data);
          }
    
          merged.push(file);
        }
        return file;
      };
    
      files.forEach(mergeRecursive);
      return merged;
    };

    flashcards.forEach((flashcard) => {
      files.push(...generateForOne(flashcard));
    });

    let newFiles = mergeFiles(files);
    let merged = mergeDuplicates(newFiles);

    console.log("Loaded tree: ", merged);

    return merged;
  }

  // Handle what happens when a file is double clicked
  const handleFileClick = (event, fileData) => {
    route(<FlashCardView data={fileData.data} fillScreen previousComponent={<FileView />} />);
  }

  // Handle what happens when a file is clicked
  const handleFileSingleClick = (event, fileData) => {
    const files = document.querySelectorAll('.file-cont');
    const folders = document.querySelectorAll('.folder-cont');

    const items = [...files, ...folders];

    items.forEach((item) => {
      if (item !== event.currentTarget && item) {
        item.classList.remove('folder-open');
      }
    });

    if (event.currentTarget) {
      if (!event.currentTarget.classList.contains('folder-open')) {
        if (!event.target.classList.contains('fa-chevron-right') && !event.target.classList.contains('fa-chevron-down')) {
          event.currentTarget.classList.add('folder-open');
        }
      } else {
        event.currentTarget.classList.remove('folder-open');
      }
    }
  }

  // Handle what happens when a folder is clicked
  const handleFolderClick = (event, fileData) => {
    const files = document.querySelectorAll('.file-cont');
    const folders = document.querySelectorAll('.folder-cont');

    const items = [...files, ...folders];

    items.forEach((item) => {
      if (item !== event.currentTarget && item) {
        item.classList.remove('folder-open');
      }
    });

    if (event.currentTarget) {
      if (!event.currentTarget.classList.contains('folder-open')) {
        if (!event.target.classList.contains('fa-chevron-right') && !event.target.classList.contains('fa-chevron-down')) {
          event.currentTarget.classList.add('folder-open');
        }
      } else {
        event.currentTarget.classList.remove('folder-open');
      }
    }
  }

  // Preview file component
  const PreviewFile = ({ selectedFile }) => {
    console.log("Selected file: ", selectedFile);

    const recursiveCountOfFlashcards = (file, type="item") => {
      let count = 0;

      file.data.forEach((item) => {
        if (item.type === "folder") {
          count += recursiveCountOfFlashcards(item, type);
          if (type === "folder" || type === "item") {
            count++;
          }
        } else {
          if (type === "file" || type === "item") {
            count++;
          }
        }
      });

      return count;
    }

    if (selectedFile === null) {
      return (
        <div className='preview-main-cont'>
          <center>
            <div className='flashcard-group'>
              <div className="flashcard" />
              <div className="flashcard" />
              <div className="flashcard" />
            </div>
            <p>Select a file to preview</p>
          </center>
        </div>
      )
    }

    if (selectedFile.type === 'folder') {
      return (
        <div className='preview-main-cont' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', maxWidth: '100%' }}>
          <FontAwesomeIcon icon={faFolder} style={{ fontSize: '10rem', marginBottom: '20px' }}/>
          <h2>{selectedFile.title}</h2>
          <hr style={{ width: '80%' }}/>
          <p>Subfolders in folder: {recursiveCountOfFlashcards(selectedFile, "folder")}</p>
          <p>Flashcards in folder: {recursiveCountOfFlashcards(selectedFile, "file")}</p>
        </div>
      )
    }

    return (
      <div className='preview-main-cont' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', maxWidth: '100%' }}>
        <h2>{selectedFile.title} - <i style={{ fontWeight: 'normal' }}>{selectedFile.data.author}</i></h2>
        <FlashCardView 
          data={selectedFile.data} 
          className="flash-view-preview" 
          showTopBar={false} 
          updateToCloud={false}
          cardFontSize='.5rem'    
          cardWidth='95%'
          useCardWidth
          sx={{
            height: '90%',
            width: 'calc(100% - 20px)'
          }} 
        />
      </div>
    )
  }


  // Recursively load files
  const RecursivelyLoadFiles = ({ className, files, keyCount=0, onItemDeselect=()=>{}, onFolderChange=()=>{}, onItemSelect=()=>{}, defaultOpen=true, sx={} }) => {
    function Folder({ file, index=0, onClickFol=()=>{}, onItemSelectFol=()=>{}, onItemDeselectFol=()=>{}, onFolderChangeFol=()=>{} }) {
      const [faIcon, setFaIcon] = useState(defaultOpen? faChevronDown : faChevronRight);
      const [open, setOpen] = useState(defaultOpen);
  
      const handleFolderClick = (e, file) => {
        onClickFol(e, file);
        
        if (e.currentTarget.classList.contains('folder-open')) {
          onItemSelectFol(e, { file: file, index: index, type: file.type });
        } else {
          if (!e.target.classList.contains('chevron-change')) {
            onItemDeselectFol(e, { file: file, index: index, type: file.type });
          }
        }
      };
  
      const handleFolderExpand = (e, file) => {
        if (faIcon === faChevronRight) {
          setFaIcon(faChevronDown);
          onFolderChangeFol(e, { folder: file, index: index, state: 'open' });
        } else {
          setFaIcon(faChevronRight);
          onFolderChangeFol(e, { folder: file, index: index, state: 'closed' });
        }
        setOpen((prev) => !prev);
      };
  
      return (
        <div className="folder-outer-cont" key={index} id={`${index};${file.path}`} path={file.path} >
          <div className="folder-cont" onClick={(e) => handleFolderClick(e, file)} onDoubleClick={(e) => handleFolderExpand(e, file)} id={`${index};${file.path}`} path={file.path}>
            <FontAwesomeIcon className="chevron-change" icon={faIcon} style={{ marginRight: '10px', height: 'stretch' }} onClick={(e) => handleFolderExpand(e, file)}/>
            <FontAwesomeIcon icon={faFolder} style={{ marginRight: '10px' }}/>
            <p 
              style={{ 
                userSelect: 'none',
                overflow: 'hidden',
                textOverflow: 'ellipsis', 
                whiteSpace: 'nowrap',
              }}
            >
              {file.title}
            </p>
          </div>
          {open && (
            <RecursivelyLoadFiles 
              files={file.data} 
              className='file-manager-cont' 
              key={keyCount + 1} 
              onItemSelect={onItemSelect} 
              onItemDeselect={onItemDeselect} 
              onFolderChange={onFolderChange} 
            />
          )}
        </div>
      );
    }
  
    function File({ file, index, onDoubleClick=()=>{}, onClickFile=()=>{}, onFileSelectFile=()=>{}, onFileDeselectFile=()=>{} }) {
      // TODO: fix double click for mobile
      return (
        <div 
          className="file-cont" 
          key={index} 
          id={`${index};${file.path}`} 
          onClick={(e)=> {
            onClickFile(e, file);
            if (e.currentTarget.classList.contains('folder-open')) {
              onFileSelectFile(e, { file: file, index: index, type: file.type });
            } else {
              onFileDeselectFile(e, { file: file, index: index, type: file.type });
            }
          }} 
          onDoubleClick={(e) => onDoubleClick(e, file)} path={file.path}
        >
          <FontAwesomeIcon icon={faFilePen} style={{ marginRight: '10px' }}/>
          <p 
            style={{ 
              userSelect: 'none',
              overflow: 'hidden',
              textOverflow: 'ellipsis', 
              whiteSpace: 'nowrap',
            }}
          >
            {file.title}
          </p>
        </div>
      );
    }
  
    return (
      <div className={`files-main-cont ${className}`} key={keyCount} style={{...sx}}>
        {files.map((file, index) => {
          if (file.type === 'file') {
            return (
              <File 
                file={file} 
                index={index} 
                key={index} 
                onDoubleClick={(e) => handleFileClick(e, file)} 
                onClickFile={(e) => handleFileSingleClick(e, file)} 
                onFileSelectFile={onItemSelect} 
                onFileDeselectFile={onItemDeselect}
              />
            );
          } else {
            return (
              <Folder 
                file={file} 
                index={index} 
                key={index} 
                onClickFol={(e) => handleFolderClick(e, file)} 
                onFolderChangeFol={onFolderChange} 
                onItemSelectFol={onItemSelect} 
                onItemDeselectFol={onItemDeselect} 
              />
            );
          }
        })}
      </div>
    );
  };

  // ===== FILE MANAGEMENT FUNCTIONS =====
  // TODO: fix search system
  const searchForItem = (path, onFound=()=>{}, files=nestedFiles) => {
    for (let i = 0; i < files.length; i++) {
      if (files[i].path === path) {
        onFound(files, files[i]);
        return files[i];

      } else if (files[i].type === 'folder') {
        searchForItem(path, onFound, files[i].data);

      } else {
        onFound(null);
        return null;
      }
    }
  }

  // Insert item into the nested files
  const insertItem = (item, path, onInsert=()=>{}, files=nestedFiles) => {
    for (let i = 0; i < files.length; i++) {
      if (files[i].path === path) {
        onInsert(files[i]);
        return files[i];

      } else if (files[i].type === 'folder') {
        searchForItem(path, onInsert, files[i].data);

      } else {
        onInsert(null);
        return null;
      }
    }
  }

  // Update item in the nested files
  const updateItem = (item, path) => {
    searchForItem(path, (files, foundItem) => {
      if (foundItem !== null) {
        foundItem = item;
      }
    });
  }

  // Delete item from the nested files
  const deleteItem = (path) => {
    searchForItem(path, (parentList, foundItem) => {
      if (foundItem !== null) {
        parentList.splice(parentList.indexOf(foundItem), 1);
      }
    })
  }

  // Handle file import
  const handleFileImport = async (file) => {
    if (file === null) {
      toast.error('No file selected');
      return Promise.reject('No file selected');
    }

    if (file.name.endsWith('.flsh')) {
      let reader = new FileReader();

      reader.onload = async (e) => {
        let data = e.target.result;

        parseFLSHintoObj(data).then((result) => {
          if (!result.success) {
            console.error('Invalid file decoding', result.error);
            toast.error('File Import failed');
            return Promise.reject('Invalid file decoding');
          }
  
          let actualFlashcard = result.data.data;
          route(<SimpleEditor 
            flashcardData={actualFlashcard} 
            previousComponent={<FileView />}
            editMode
          />, 'strict');

          toast.success('File imported successfully');
        });
      }

      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        toast.error('File reading failed');
      };

      console.log("Starting to read the file as text");
      reader.readAsText(file);
    } else {
      console.error('File does not end with .flsh');
      toast.error('Invalid file type');
      return Promise.reject('Invalid file type');
    }
  } 

  const ImportPreview = ({ isShown, className, handleFileImport=()=>{} }) => {
    const [dragging, setDragging] = useState(false);
  
    useEffect(() => {
      const handleDragOver = (e) => {
        e.preventDefault();
        setDragging(true);
      };
  
      const handleDragLeave = (e) => {
        e.preventDefault();
        setDragging(false);
      };
  
      const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);
        const files = e.dataTransfer.files;
        if (files.length > 0 && files[0].name.endsWith('.flsh')) {
          handleFileImport(files[0]);
        }
      };
  
      const dropZone = document.querySelector('.import-box');
      if (dropZone) {
        dropZone.addEventListener('dragover', handleDragOver);
        dropZone.addEventListener('dragleave', handleDragLeave);
        dropZone.addEventListener('drop', handleDrop);
      }
  
      return () => {
        let dropZone = document.querySelector('.import-box');

        if (dropZone) {
          dropZone.removeEventListener('dragover', handleDragOver);
          dropZone.removeEventListener('dragleave', handleDragLeave);
          dropZone.removeEventListener('drop', handleDrop);
        }
      };
    }, []);
  
    if (isShown) {
      return (
        <div className={`import-preview-blur-cont ${className}`}>
          <div className='import-inner-box' style={{position: 'relative'}}>
            <NiceButton
              hasIcon
              icon={faXmark}
              onClick={() => setImportOpen(false)}
              sx={{ 
                position: 'absolute', 
                top: '10px', 
                left: '10px', 
                width: '30px', 
                height: '30px' 
              }}
            />
            <h2>Import file</h2>
            <hr />
            <p>Drag and drop a file here to import it or select it</p>
            <div className={`import-box ${dragging ? 'dragging' : ''}`}>
              <FontAwesomeIcon icon={faImages} style={{ fontSize: '135px', marginBottom: '20px' }} />
              <input type='file' accept='.flsh' onChange={(e) => handleFileImport(e.target.files[0])} />
            </div>
          </div>
        </div>
      );
    }
  
    return null;
  };

  useEffect(() => {
    // Fetch flashcards from database
    listFlashcardsByUserID(getCurrentUser().user.uid)
    .then((flashcards) => {
      console.log("Flashcards loaded!: ", flashcards);

      if (flashcards === null) {
        flashcards = [];
      }

      setFlashcardData(flashcards);

      setNestedFiles(generateRecursiveFileStructure(flashcards))

      setLoaded(true);
    })
    .catch((error) => {
      console.error('Error loading flashcards: ', error);
    });
  }, []);

  // useEffect(() => {
  //   if (nestedFiles !== null) {
  //     searchForItem('/Romantika na SLO', (foundItem) => {
  //       console.log("Found item: ", foundItem);
  //     });
  //   }
  // }, [nestedFiles]);

  if (!loaded && nestedFiles === null) {
    return <Loader primaryColor={accentColor} scale={2} />;
  }

  return (
    <Suspense fallback={<Loader primaryColor={accentColor} scale={2} />}>
      <div className={`file-view-main ${className}`}>
        <main>
          <div className='top-bar'>
            <BackButton sx={{ position:'unset', marginLeft: '10px' }}/>
            <NiceButton 
              onClick={(e) => route(<SimpleEditor previousComponent={<FileView />}/>, 'strict')} 
              hasIcon
              icon={faFileCirclePlus}
            />
            {/* TODO: Add the new file and folder into a dropdown */}
            {/* <NiceButton
              onClick={() => {}}
              hasIcon
              icon={faFilePen}
              sx={{ marginRight: '10px' }}
            /> */}
            <NiceButton
              onClick={(e) => setImportOpen(true)}
              hasIcon
              icon={faFileImport}
            />
            <NiceButton
              onClick={() => {}}
              hasIcon
              icon={faFolderPlus}
            />
          </div>
          <div className='main-cont-files'>
            <RecursivelyLoadFiles 
              files={nestedFiles} 
              className='file-manager-cont root-dir' 
              onItemSelect={(target, e) => {
                //TODO: Implement shift and ctrl select
                setSelectedItem(e.file);
              }}
              onFolderChange={(target, e) => {
                console.log("Folder expanded -> : ", e);
              }}
              onItemDeselect={(target, e) => {
                setSelectedItem(null);
              }}
              sx={{
                width: '100%'
              }}
            />

            {isMobileDevice() || windowSize[0] <= 1250? null : 
              <PreviewFile 
                selectedFile={selectedItem}
              />
            }
          </div>
          <ImportPreview isShown={importOpen} handleFileImport={(e) => handleFileImport(e)} />
        </main>

        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          pauseOnHover={false}
          closeOnClick
          rtl={false}
          theme={getTheme()}
        />
      </div>
    </Suspense>
  );
}

export default FileView;
