import React, { Suspense, useEffect, useRef, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { faEye, faEyeSlash, faGears, faSave, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BackButton from '../../components/BackButton/BackButton';
import Loader from '../../components/Loader/Loader';
import SignIn from '../sign-in/signIn';
import NiceButton from '../../components/NiceButton/_layout';

import { accentColor, getTheme } from '../../utils/projectColors';
import { getCurrentUser, getUserDataID, signOut } from '../../scripts/accountManager';
import { route } from '../../utils/router';

import './profilePage.scss';

function ProfilePage({ className }) {
  const [userData, setUserData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [curProfilePic, setCurProfilePic] = useState(null);
  const fileInputRef = useRef(null);

  // TODO: Make profile page nice and make it work
  // TODO: Make it responsive

  useEffect(() => {
    let userSignedIn = getCurrentUser().status;

    console.log("User signed in: ", userSignedIn, getCurrentUser().user);

    if (userSignedIn) {
      let userData = getCurrentUser().user;
      
      getUserDataID(userData.uid).then((data) => {
        console.log("User data: ", data);
        setUserData(data.user);
        setCurProfilePic(data.user.photoURL);

        setLoaded(true);
      });
    } else {
      route(<SignIn />);
    }
  }, []);

  // If the user is not signed in, return the sign in page
  if (!loaded) {
    return <Loader primaryColor={accentColor} scale={2} />;
  }

  // User stats component
  const UserStats = () => {
    const [passwordShown, setPasswordShown] = useState(false);

    return (
      <div className='usr-stats'>
        <img src={curProfilePic} alt='Profile' />
        <div>
          <h2><span style={{ fontWeight: 'normal' }}>username:</span> {userData.displayName}</h2>
          <h2><span style={{ fontWeight: 'normal' }}>email:</span> {userData.email}</h2>
          <div style={{ display:'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <h2>
              <span style={{ fontWeight: 'normal' }}>password:</span> {
                userData.password === null? 
                  `none ('${userData.provider}')`
                  :
                  passwordShown? 
                    userData.password 
                    : 
                    '*'.repeat(userData.password.length)
                }
            </h2>
            <div 
              style={{ 
                marginLeft: '10px', 
                cursor: 'pointer', 
                height: '100%', 
                display: userData.password === null? 
                  'none' 
                  : 
                  'flex', 
                alignItems: 'center', 
                justifyContent: 'center' 
              }}
            >
              <FontAwesomeIcon 
                icon={passwordShown? faEyeSlash : faEye} 
                onClick={() => setPasswordShown((prev) => !prev)} 
              />
            </div>
          </div>
          <div>
            <h4>Joined: {userData.metadata.creationTime}</h4>
          </div>
        </div>
      </div>
    );
  }

  // A separator component
  const Separator = ({ className, sepDistance='10px', lineStyle={}, boxStyle={} }) => {
    return (
      <div className={`separator ${className}`} style={{ ...boxStyle }}>
        <div className='line' style={{ marginLeft: sepDistance, marginRight: sepDistance, ...lineStyle }}></div>
      </div>
    );
  }

  // A user profile changing component
  const UserProfileSettings = ({ className, fileInputRef }) => {
    const handleButtonClick = () => {
      fileInputRef.current.click();
    };

    return (
      <div className={`user-config ${className}`}>
        <h2>Profile settings</h2>
        <div>
          <div className='profile-settings-left'>
            <h3>Change profile picture</h3>
            {/* a MUI Button that chooses a file */}
            <NiceButton 
              variant='contained' 
              component='label'
              useChildren
              width='150px'
              height='40px'
              onClick={() => handleButtonClick()}
            >
              Upload Picture
              <input 
                type='file' 
                hidden 
                accept='.jpeg, .png, .jpg' 
                ref={fileInputRef}
                onChange={(e) => setCurProfilePic(URL.createObjectURL(e.target.files[0]))}
              />
            </NiceButton>
          </div>
          <div className='profile-settings-right'>
            <h3>Change username</h3>
            <input type='text' />
            <h3>Change email</h3>
            <input type='email' />
            <h3>Change password</h3>
            <input type='password' />
          </div>
        </div>
      </div>
    );
  }

  // Handle sign out
  const handleSignOut = () => {
    signOut().then((res) => {
      if (res.status) {
        toast.success('Signed out successfully!');
        setTimeout(() => {
          route(<SignIn />);
        }, 800);
      } else {
        toast.error('Error signing out!');
      }
    });
  }

  return (
    <Suspense fallback={<Loader primaryColor={accentColor} scale={2} />}>
      <div className={`profile-page-main ${className}`}>
        <div className='top-buttons on-top'>
          <BackButton />
          <div style={{ display: 'flex' }}>
            <NiceButton 
              onClick={() => {}} 
              useChildren 
              className='sign-out-btn on-top' 
            >
              <FontAwesomeIcon icon={faGears} />
            </NiceButton>
            <NiceButton 
              onClick={() => handleSignOut()} 
              useChildren 
              className='sign-out-btn on-top' 
              width='130px' 
            >
              <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '10px' }} />
              Sign Out
            </NiceButton>
          </div>
        </div>
        <main>
          <div className='container cont-profile-stats'>
            <UserStats />
            <Separator />
            <UserProfileSettings fileInputRef={fileInputRef} />
            <Separator />
            <NiceButton
              onClick={() => {}}
              useChildren
              width='40%'
              sx={{
                margin: '10px',
                minWidth: '150px',
                maxWidth: '400px',
              }}
            >
              Save Changes
              <FontAwesomeIcon icon={faSave} style={{ marginLeft: '10px' }} fontSize='1.2rem'/>
            </NiceButton>
          </div>
        </main>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          pauseOnHover={false}
          closeOnClick
          rtl={false}
          theme={getTheme()}
        />
      </div>
    </Suspense>
  );
}

export default ProfilePage;